import { Component, ViewChild, ElementRef, OnDestroy } from "@angular/core";
import { OnInit, Inject, Renderer2 } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { ActivatedRoute, Router, RouterStateSnapshot, NavigationEnd } from "@angular/router";
import { ShoppingCartService } from "../../services/shopping-cart.service";
import { ShoppingCart } from "../../../models/shopping-cart";
import { AuthenticationService } from "../../services/authentication.service";
import { ContactService } from "../../services/contact.service";
@Component({
  selector: "app-nav-menu",
  templateUrl: "./nav-menu.component.html",
  styleUrls: ["./nav-menu.component.css"],
})
export class NavMenuComponent implements OnInit, OnDestroy {
  
  //@ViewChild("hMenuDiv", { static: false }) divView: ElementRef;
  public shoppingCartItemCount: number = 0;
  subscription0: Subscription; 
  public fixedtop = false;
 keyword : string = "";
  //cart$: Observable<ShoppingCart>;
  
  //user: User;
  constructor(
    private contactService: ContactService,
    public auth: AuthenticationService,
    private renderer: Renderer2,
    public shoppingCartService: ShoppingCartService,
    private router: Router,
    private route: ActivatedRoute,
  ) {

    route.queryParams.subscribe((p) => {
      if (p["keyword"])
        this.keyword = p["keyword"];
      else
        this.keyword = "";
    });

  }

  
  firstname:"";
  async ngOnInit() {

    //this.router.events.subscribe((evt) => {
    //  if (!(evt instanceof NavigationEnd)) {
    //    return;
    //  }
    //  console.log("rout")
      
    //});
    if (this.auth.currentUserValue) {
      this.subscription0 = this.contactService.getContact(this.auth.currentUserValue.id).subscribe(c => {
        this.firstname = c.firstname;
      });
    }

    this.renderer.listen(window, "scroll", ($event) => {
      if (window.scrollY > 70 && window.location.pathname == "/") {
        this.fixedtop = true;
      } else {
        this.fixedtop = false;
      }
    });
    //localStorage.setItem('ali', "ali");
  }

  ngOnDestroy() {
    if (this.subscription0)
      this.subscription0.unsubscribe();
   //   this.isExpanded = false;
  }


  search() {
    if (this.keyword )
      this.router.navigate(['/products'], { queryParams: { 'keyword': this.keyword.trim() } });
    else
      this.router.navigate(['/products']);
    

  }

  logout() {
    this.auth.logout();
    this.router.navigate(['/login']);

  }


  login() {
    var snapshot = this.router.routerState.snapshot;
    this.router.navigate(['/login'], { queryParams: { returnUrl: snapshot.url } });
  }

  

  //collapse() {
  //  //this.isExpanded = false;
  //}


}
