import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../../models/user';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private readonly Endpoint = "/api/users";
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));

    //  console.log("------------------", localStorage.getItem('currentUser'));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
  //  console.log(JSON.parse(localStorage.getItem('cUser')))
      return this.currentUserSubject.value;
  }

  updateUser(user) {
    localStorage.setItem('currentUser', JSON.stringify(user));
  }
  login(username: string, password: string) {
    return this.http.post<any>(`${this.Endpoint}/authenticate`, { username, password })
      .pipe(map(user => {
        // store user details and basic auth credentials in local storage to keep user logged in between page refreshes
        user.authdata = window.btoa(username + ':' + password);
        this.currentUserSubject.next(user);
        //localStorage.setItem('cUser', JSON.stringify(this.currentUserSubject.value));
        localStorage.setItem('currentUser', JSON.stringify(user));
        return user;
      }));
  }

 





  requestResetPassword(username) {
    return this.http.post<any>(`${this.Endpoint}/requestresetpassword`, { username, password: "requestresetpassword" })
      .pipe(map(user => { return user; }));
  }
  resetPassword(username, password, activationKey) {
    return this.http.post<any>(`${this.Endpoint}/resetpassword`, { username, password, activationKey })
      .pipe(map(user => { return user; }));

  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }
}
