import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ProductReview } from "../../models/product";

@Injectable()
export class ProductService {
  private readonly Endpoint = "/api/products";
  constructor(private http: HttpClient) { }

  getManufacturers() {
    return this.http.get("/api/manufacturers");
  }

  getCategories(cat) {
    return this.http.get("/api/categories/?cat=" + cat  );
  }

  getProduct(id) {
    return this.http.get("/api/products/" + id);
  }

  // get() {
  //   return this.http.get("/api/products/");
  // }
  getProducts(filter) {

 //   console.log(this.Endpoint + "?" + this.toQueryString(filter));
    return this.http.get(
      this.Endpoint + "?" + this.toQueryString(filter)
    );
  }

  toQueryString(obj) {
    var parts = [];
     var a = "";
    for (var property in obj) {
      var value = obj[property];
      if (value != null && value != undefined)
        if (Array.isArray(value))
          value.map(a => parts.push(encodeURIComponent(property) + '=' + encodeURIComponent(a)))
      else
        parts.push(
          encodeURIComponent(property) + "=" + encodeURIComponent(value)
        );
    }

    return parts.join("&");
  }
  delete(id) {
    return this.http.delete("/api/products/" + id);
  }

  update(product) {
    return this.http.put<any>("/api/products/" + product.id, product);
  }
  create(product) {
    return this.http.post<any>("/api/products", product);
  }

  saveProductReview(productReview: ProductReview) {
    return this.http.post<any>("/api/products/" + productReview.ProductId + "/reviews/", productReview);

  }

}
