import { Observable, Subscriber, Subscription } from "rxjs";
//import { ShoppingCart } from '../../../shared/models/shopping-cart';
//import { ShoppingCartService } from '../../../shared/services/shopping-cart.service';
import { Component, OnInit, OnDestroy } from "@angular/core";
import { AuthenticationService } from "../../../services/authentication.service";
import { Router } from "@angular/router";
import { first } from "rxjs/operators";
import { ContactService } from "../../../services/contact.service";
import { trigger } from "@angular/animations";
import { User } from "../../../../models/user";


@Component({
  selector: "app-my-details",
  templateUrl: "./my-details.component.html",
  styleUrls: ["./my-details.component.css"],
})
export class MyDetailsComponent implements OnInit, OnDestroy {
  loading = true;
  btnloading = false;

  subscription1: Subscription; subscription2: Subscription; subscription3: Subscription; contactSubscription: Subscription;
  passwordSuccessfullyUpdated = false;
  contact = {
    Id: 0,
    email: "",
    EmailConfirmed: false,
    postcode: "",
    password: "",
    firstName: "",
    lastName: "",
    unit: "",
    address: "",
    suburb: "",
    state: "",
    subscribe: true,

  };
  //cart = new ShoppingCart(0);
  cartId;
  successfullyUpdated = false; uPassword = false; deliveryinfo = true;
  constructor(
    private contactService: ContactService,
    public auth: AuthenticationService,
    private router: Router) { }

  async ngOnInit() {
    //    console.log(this.auth.currentUserValue);

    if (this.auth.currentUserValue) {
      this.contactService.getContact(this.auth.currentUserValue.id).subscribe(c => {
      
        this.contact.email = c.email;
        this.contact.firstName = c.firstname;
        this.contact.lastName = c.lastname;
        this.contact.unit = c.unit;
        this.contact.address = c.address;
        this.contact.suburb = c.suburb;
        this.contact.state = c.state;
        this.contact.postcode = c.postcode;
        this.contact.Id = c.id;
        this.contact.subscribe = c.subscribe;
        this.loading = false;

      }
        , error => {
          this.auth.logout();
          var snapshot = this.router.routerState.snapshot;
          this.router.navigate(['/login'], { queryParams: { returnUrl: snapshot.url } });
        }
      )

      //this.contact.email = this.auth.currentUserValue.username;
      //this.contact.firstName = this.auth.currentUserValue.firstName;
      //this.contact.lastName = this.auth.currentUserValue.lastName;
      //this.contact.unit = this.auth.currentUserValue.unit;
      //this.contact.address = this.auth.currentUserValue.address;
      //this.contact.suburb = this.auth.currentUserValue.suburb;
      //this.contact.state = this.auth.currentUserValue.state;
      //this.contact.postcode = this.auth.currentUserValue.postcode;
      //this.contact.Id = this.auth.currentUserValue.id;
      //this.contact.subscribe = this.auth.currentUserValue.subscribe;

    }
    //this.cart$ = await this.shoppingCartService.getCart();

  }

  ngOnDestroy() {
    if (this.subscription1)
      this.subscription1.unsubscribe();
    if (this.subscription2)
      this.subscription2.unsubscribe();
    if (this.contactSubscription)
      this.contactSubscription.unsubscribe();
    if (this.subscription3)
      this.subscription3.unsubscribe();

  }



  //createAccount() {
  //  this.contact.EmailConfirmed = false;
  //  //console.log(this.contact);
  //  this.subscription1 = this.contactService.create(this.contact).subscribe(
  //    x => {
  //      if (this.contact.password != "")
  //        this.login_contact();
  //    });
  //  this.step2 = false;
  //  this.step3 = true;
  //}



  //async continueAsGuest() {


  //  this.shoppingCartService.update(this.shoppingCartService.shoppingCartValue.id, this.contact.email).then();
  //  this.alreadyExists = false;
  //  this.step2 = false;
  //  this.step3 = true;
  //}

  saveContact() {
    this.btnloading = true;
    this.contact.password = "";
    this.subscription2 = this.contactService.create(this.contact).subscribe(
      c => {
        setTimeout(() => { this.btnloading = false; this.successfullyUpdated = true; }, 1000)
        setTimeout(() => { this.successfullyUpdated = false; }, 3000)
      }
    );
    

  }

  contactUpdatePassword() {

    this.subscription2 = this.contactService.create(this.contact).subscribe(
      x => {




        this.login_contact();
        this.passwordSuccessfullyUpdated = true
        this.successfullyUpdated = false;
      }

    );
    this.successfullyUpdated = true;

  }
  saveContactDetails() {
    this.btnloading = true;
    this.subscription3 = this.contactService.create(this.contact).subscribe(
      c => {
          console.log(c);

        //this.auth.currentUserValue.firstName = c.firstName;
        //this.auth.currentUserValue.lastName = c.lastName;
        //this.auth.currentUserValue.unit = c.unit;
        //this.auth.currentUserValue.address = c.address;
        //this.auth.currentUserValue.suburb = c.suburb;
        //this.auth.currentUserValue.state = c.state;
        //this.auth.currentUserValue.postcode = c.postcode;
        //this.auth.currentUserValue.subscribe = c.subscribe;
        //this.auth.updateUser(this.auth.currentUserValue)
        //   this.auth.logout();
        //  this.login_contact();
        
        setTimeout(() => { this.btnloading = false; this.successfullyUpdated = true; }, 500)
        setTimeout(() => { this.successfullyUpdated = false; }, 3000)

        //console.log(this.auth.currentUserValue)

      }
    );

  }

  updatePassword() {
    this.uPassword = true;
    this.deliveryinfo = false;
  }
  accountInfo() {
    this.uPassword = false;
    this.deliveryinfo = true;

  }

  login_contact() {
    if (this.contact.password == "" || this.contact.email == "") return;
    this.auth.login(this.contact.email, this.contact.password)
      .pipe(first())
      .subscribe();
  }

  login() {
    var snapshot = this.router.routerState.snapshot;
    this.router.navigate(['/login'], { queryParams: { returnUrl: snapshot.url } });
  }


}
