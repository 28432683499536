import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";



@Injectable()
export class ContactService {

constructor(private http: HttpClient) { }

  private readonly Endpoint = "/api/contacts";
  public create(contact) {
    return this.http.post<any>(this.Endpoint, contact);
  //return this.http.post<any>("/api/shopping-cart", "");//.pipe(map(res => this.cartId = res.result.id))
  }


  public contactus(name,email,message) {
    return this.http.post<any>(this.Endpoint + "/contactus", { name: name, email: email, message: message });
  };



  getContact(id): Observable<any> {
    return this.http.get(
      this.Endpoint + "/" + id
    );
  }

  getContacts(filter): Observable<any> {
    return this.http.get(
      this.Endpoint + "?" + this.toQueryString(filter)
    );
  }

  toQueryString(obj) {
    var parts = [];
    var a = "";
    for (var property in obj) {
      var value = obj[property];
      if (value != null && value != undefined)
        if (Array.isArray(value))
          value.map(a => parts.push(encodeURIComponent(property) + '=' + encodeURIComponent(a)))
        else
          parts.push(
            encodeURIComponent(property) + "=" + encodeURIComponent(value)
          );
    }
    return parts.join("&");
  }
 //public update(cartId, buyerid) {
  //  var shoppingcart: any = { Id: Number(cartId),BuyerId: buyerid,DateTime : Date.now()}
  //  return this.http.put<any>("/api/shopping-cart/" + cartId, shoppingcart).toPromise();
    //return this.http.post<any>("/api/shopping-cart", "");//.pipe(map(res => this.cartId = res.result.id))
  //};
}
 
