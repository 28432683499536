import { Product } from "../../models/product";
import { ShoppingCartItem, ShoppingCart} from "../../models/shopping-cart"
import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { map, switchMap,take, first } from "rxjs/operators";
import { Observable, BehaviorSubject, Subject } from "rxjs";
import { ContactService } from "./contact.service";


@Injectable()
export class ShoppingCartService {
  private cartSubject: BehaviorSubject<ShoppingCart>;
  public currentCart: Observable<ShoppingCart>;
  

  public get CurrentShoppingCart(): Observable<ShoppingCart> {
    return this.currentCart.pipe(map(x => new ShoppingCart(x)));
  }

  constructor(private http: HttpClient,
    private Contactservice: ContactService) {
    this.cartSubject = new BehaviorSubject<ShoppingCart>(JSON.parse(localStorage.getItem('ShoppingCart')));
    this.currentCart = this.cartSubject.asObservable();
    
  }
  public get shoppingCartValue(): ShoppingCart {

    return this.cartSubject.value;
  }
  public get totalItemsCount() {
    if (!this.shoppingCartValue) return 0;
    let count = 0;
  for (let m in this.shoppingCartValue.shoppingCartItems) {
    count += this.shoppingCartValue.shoppingCartItems[m].quantity;
    }
    return count;
  }

  public update(cartId, buyerid) {
    var shoppingcart: any = { Id: Number(cartId),BuyerId:String(buyerid),DateTime : Date.now()}
    return this.http.put<any>("/api/shopping-cart/" + cartId, shoppingcart).toPromise();
    //return this.http.post<any>("/api/shopping-cart", "");//.pipe(map(res => this.cartId = res.result.id))
  };

  private addItemToShoppingCart(shoppingCartItem: ShoppingCartItem) {
    return this.http.post<any>("/api/shopping-cart/" + shoppingCartItem.shoppingCartId + "/items/", shoppingCartItem);

  }
  private removeItemFromShoppingCart(shoppingCartId, productId, quantity) {
    return this.http.delete<any>("/api/shopping-cart/" + shoppingCartId + "/items/" + productId + "/" + quantity);

  }
  getCartByProduct(cartId, productId) {
    return this.http.get("/api/shopping-cart/" + cartId + "/items/" + productId);
  }
   // ///////////////////////////////////////////////////////////////////////////
  getShoppingCart(cartId): Observable<ShoppingCart> {
      return this.http.get("/api/shopping-cart/" + cartId).pipe(map(x => { return new ShoppingCart(x) }));
  }

 
  saveShoppingcartToLocal(shoppingcart) {
    this.cartSubject.next(shoppingcart);
    localStorage.setItem('ShoppingCart', JSON.stringify(this.cartSubject.value));
}

  //public async AddToCart(product: Product, shoppingCartItem : ShoppingCartItem) {
  public async AddRemoveCartItem(shoppingCartItem: ShoppingCartItem, quantity) {
    this.CreateOrGetCurrentCart().pipe(first())
      .subscribe(
        data => {
          shoppingCartItem.shoppingCartId = this.shoppingCartValue.id
          shoppingCartItem.quantity = quantity;
          return this.addItemToShoppingCart(shoppingCartItem).subscribe(result => { this.saveShoppingcartToLocal(result); return result; });
          //  var citem = this.addItemToShoppingCart(shoppingCartItem).pipe(map(x => new ShoppingCart(x)));
      //    return citem.subscribe(result => { console.log(result); this.saveShoppingcartToLocal(result); return result; });
        },error => {});
   
  }


  clearCart() {
    this.saveShoppingcartToLocal(null);
    localStorage.removeItem('ShoppingCart');
  }




  CreateOrGetCurrentCart() {
    if (this.shoppingCartValue && this.shoppingCartValue.id)

      return this.getShoppingCart(this.shoppingCartValue.id);
    else
      return this.http.post<any>("/api/shopping-cart", "")
        .pipe(map(shoppingcart => {

          this.saveShoppingcartToLocal(shoppingcart);
          return shoppingcart;
        }));


  }




    //getCart(cartId): Observable<ShoppingCart> {
  //  return this.http.get("/api/shopping-cart/" + cartId + "/items/").pipe(map(x => new ShoppingCart(x)));
  //}

  //async getOrCreateCartId(): Promise<string> {
  //  let cartId = localStorage.getItem('cartId');
  //  if (cartId) return cartId;
  //  let result = await this.create();
  //  localStorage.setItem('cartId', result.result.id);
  //  return result.result.id;
  //}

  //public create() {
  //  return this.http.post<any>("/api/shopping-cart", "").toPromise();
  //};

  //public async AddToCartV1(product: Product) {
  //  var cartId = await this.getOrCreateCartId();
  //  let result;
  //  var shoppingCartItem = new ShoppingCartItem({
  //    id: 0,
  //    shoppingCartId: Number(cartId),
  //    productId: product.id,
  //    quantity: 1,
  //    unitPrice: 100
  //  });

  //  return this.addItemToShoppingCart(shoppingCartItem).toPromise();
  //  //return result; //.then(data => { return data });
  //}

  
    
  //public async RemoveFromCartV1(product: Product, quantity:number)  {
  //  var cartId = await this.getOrCreateCartId();
  //return this.removeItemFromShoppingCart(cartId,product.id,quantity).toPromise();
  //}

  
  //clearCartV1() {
  //  localStorage.removeItem('cartId');
  //  //return this.http.delete<any>("/api/shopping-cart/" + cartId + "/items/");
  //}



    //public async RemoveFromCart(product: Product, shoppingCartItem: ShoppingCartItem) {
  //  var cartId = this.shoppingCartValue.id;
  //  return this.removeItemFromShoppingCart(cartId, product.id, shoppingCartItem.q).pipe(first())
  //    .subscribe(
  //      data => {
  //        this.saveShoppingcartToLocal(data)
  //        return data;
  //      }, error => { });

  //}

}
