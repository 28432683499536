import { Component, OnInit, Input, OnChanges } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AuthenticationService } from "../../services/authentication.service";

import { ProductReview } from "../../../models/product";

import { ProductService } from "../../services/product.service";


@Component({
  selector: "app-product-review",
  templateUrl: "./product-review.component.html",
})
export class ProductReviewComponent implements OnInit, OnChanges{
  @Input() productReviews: ProductReview[];
  @Input() productID: number;
  
  
  // ////////////////////////////////////////////////////////////////////////////////////////
  //private _productReview = new BehaviorSubject<ProductReview[]>([]);
  //// change data to use getter and setter
  //@Input()
  //set productReview(value) {
  //  this._productReview.next(value);
  //};
  //  get productReview() {
  //  return this._productReview.getValue();
  //}
  //////////////////////////////////////////////////////////////////////
  // now we can subscribe to it, whenever input changes,
  //  this._productReview
  // .subscribe(x => {
  //    console.log("x:", x);
  //     });
  // ////////////////////ngOnInit////////////////////////////////////////
  
  error = '';
  showThankYou = false;
  ratingClicked: number;
  itemIdRatingClicked: string;
  newReview: ProductReview
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private productService: ProductService,
    private authenticationService: AuthenticationService
  ) { }


  ngOnChanges() {
    if (this.productReviews)
      this.productReviews = this.productReviews.filter(f => f.qa == false)
  }
  ngOnInit() {
    this.productReviews = this.productReviews.filter(f => f.qa == false)
    //console.log(this.productReviews)
    this.newReview = {
      Id: 0,
      ProductId: this.productID,
      Approved :false,
      qa : false,
      ContactID: this.authenticationService.currentUserValue != null ? this.authenticationService.currentUserValue.id : null,
      ContactName: this.authenticationService.currentUserValue != null ? this.authenticationService.currentUserValue.firstName :"",
      Email: this.authenticationService.currentUserValue != null ? this.authenticationService.currentUserValue.username : "",
      Title: "",
      Comment: "",
      Reply: "",
      rating: this.ratingClicked,
      Created: null,
      LastUpdate: null
    };
  }

  submitReview() {
    if (this.ratingClicked)
      this.newReview.rating = this.ratingClicked;
    else
      this.newReview.rating = 3;
    this.productService.saveProductReview(this.newReview).subscribe();
    this.showThankYou = true;
 }


  public arr(n: number): any[] {
    var intvalue = Math.floor(n);
    if (intvalue)
      return Array(intvalue);
    return null;
  }

  ratingComponentClick(clickObj: any): void {
    this.ratingClicked = clickObj.rating;
  }



  get AverageRating() {
    var sum: number = 0;
    for (let m in this.productReviews)
      sum += Number(this.productReviews[m].rating);
    return (sum / this.productReviews.length).toFixed(1);
    
  }

  get AverageRatingRounded() {
    var sum: number = 0;
    
    for (let m in this.productReviews) 
      sum += Number(this.productReviews[m].rating);
    return Math.round(sum / this.productReviews.length) ;
  }




}



// https://developers.google.com/chart/interactive/docs/gallery/barchart#configuration-options
  //public chart = {
  //  title: '',
  //  type: ChartType.BarChart,
  //  data: [
  //    ['5 Stars', 3],
  //    ['4 stars', 2],
  //    ['3 stars', 0],
  //    ['2 Stars', 0],
  //    ['1 Star', 0]
  //  ],
  //  columnNames: ['Stars', ''],
  //  options: {
  //    animation: {
  //      duration: 250,
  //      easing: 'ease-in-out',
  //      startup: true
  //    },

  //    boxStyle: {
  //      stroke: '#ccc',
  //      strokeWidth: 1,
  //      gradient: {
  //        color1: '#f3e5f5',
  //        color2: '#f3e5f5',
  //        x1: '0%',
  //        y1: '0%',
  //        x2: '100%',
  //        y2: '100%'
  //      }
  //    },
  //  }
  //};
