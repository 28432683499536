import { Component, Inject, Input, OnInit, OnDestroy } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { User } from "../../../models/user";
import { ShoppingCartService } from "../../services/shopping-cart.service";
import { ShoppingCart } from "../../../models/shopping-cart";
import { Order } from "../../../models/order";
import { AuthenticationService } from "../../services/authentication.service";
import { ContactService } from "../../services/contact.service";
import { OrderService } from "../../services/order.service";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
@Component({
  selector: "app-orderconfirmation",
  templateUrl: "./orderconfirmation.component.html",
})
export class OrderConfirmationComponent implements OnInit, OnDestroy {
 // @Input('user') user;
 // @Input('order') order;
  cartId;
  contact = {};
  order = {};
  subscription1: Subscription; subscription2: Subscription; subscription3: Subscription; 

  cart = new ShoppingCart(0);
  constructor(@Inject(DOCUMENT) private document: Document,
    public auth: AuthenticationService,
    private contactService: ContactService,
    private shoppingCartService: ShoppingCartService,
    private orderService: OrderService,
    private router: Router,
  ) { }
  async ngOnInit() {

    //this.cartId = await this.shoppingCartService.getOrCreateCartId();
    //this.subscription2 = this.shoppingCartService.getCart(this.cartId).subscribe(r => {

    this.subscription1 = this.contactService.getContact(this.auth.currentUserValue.id).subscribe(c => { this.contact = c;});
    this.subscription2 = this.shoppingCartService.CurrentShoppingCart.subscribe(r => {
      this.cart = r;
      //console.log(this.cart);
      //if (this.cart.shoppingCartItems.length == 0)
      //  this.router.navigate(['/products']);
    });
    this.subscription3 = this.orderService.getOrder(this.shoppingCartService.shoppingCartValue.id).subscribe(
      x => {
         //console.log(x);
        this.order = x;
        this.shoppingCartService.clearCart();
      }
    )

    
    this.document.body.classList.add("orderconfirmation");
  }
  ngOnDestroy(): void {
    this.shoppingCartService.clearCart();
    this.document.body.classList.remove("orderconfirmation");

    if (this.subscription1)
      this.subscription1.unsubscribe();
    if (this.subscription2)
      this.subscription2.unsubscribe();
    if (this.subscription3)
      this.subscription3.unsubscribe();

  }
}
