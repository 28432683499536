import { Component, ViewChild, ElementRef, HostListener } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { AuthenticationService } from "./services/authentication.service";
import { User } from "../models/user";
import { ShoppingCart } from "../models/shopping-cart";

import { ShoppingCartService } from "./services/shopping-cart.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent {
 
  currentUser: User;
  CurrentShoppingCart: ShoppingCart;


   constructor(
    private router: Router) {

     console.log("readCookie", this.getCookie("ASP.NET_SessionId"))

    //    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
  //  this.shoppingcardService.currentCart.subscribe(x => this.CurrentShoppingCart = x);

    //auth.user$.subscribe((user) => {
    //  if (!user) return;
    //  userService.save(user);
    //  let returnUrl = localStorage.getItem("returnUrl");
    //  if (!returnUrl) return;

    //  localStorage.removeItem("returnUrl");
    //  router.navigateByUrl(returnUrl);
    //});
  }


 getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

 
  
  onActivate(event) {
    window.scroll(0, 0);
    document.getElementById("hMenuDiv").classList.remove("show");
  }

}
