import { Component, OnInit, OnDestroy } from '@angular/core';
import { ShoppingCartService } from '../../services/shopping-cart.service';
import {  Product } from '../../../models/product';
import { ShoppingCart, ShoppingCartItem } from '../../../models/shopping-cart';
import { map, switchMap, take } from "rxjs/operators";
import { Observable, Subscription } from "rxjs";
import { Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';


@Component({
  selector: 'app-shoping-cart',
  templateUrl: './shoping-cart.component.html',
  styleUrls: ['./shoping-cart.component.css']
})
export class ShopingCartComponent implements OnInit, OnDestroy {

  subscription1: Subscription;  
  constructor(private shoppingCartService: ShoppingCartService, private router: Router
    , private titleService: Title, private metaService: Meta
  ) { }
  
  cart= new ShoppingCart(0);

  //cart$: Observable<ShoppingCart>;
  async ngOnInit() {

    this.titleService.setTitle("Shopping Cart - Fitshake");
    //let cartId = await this.shoppingCartService.getOrCreateCartId();

    //this.cart$ = this.shoppingCartService.getCart(cartId);

    this.shoppingCartService.CurrentShoppingCart.subscribe(x => { this.cart = x;  this.cart.shoppingCartItems.sort((a, b) => (a.id < b.id ? -1 : 1))});
    //this.shoppingCartService.getCart(this.shoppingCartService.shoppingCartValue.id).subscribe(r => { this.cart = r; console.log("console.log(this.cart)",this.cart) });
  }
  


  
  async removeItemFromCart(product: Product, cartitem: ShoppingCartItem) {
    //cartitem.quantity = 0;
    this.shoppingCartService.AddRemoveCartItem(cartitem, 0);//.then(s => this.setShoppingCartItem(s));
    //this.cart.shoppingCartItems.splice(this.cart.shoppingCartItems.findIndex(e => e.id === shoppingcartItemId), 1);
    //this.shoppingCartService.RemoveFromCart(shoppingcartItem.product, shoppingcartItem).then();
  }

  async addToCart(cartitem: ShoppingCartItem) {
    //cartitem.quantity = 1;
    this.shoppingCartService.AddRemoveCartItem(cartitem,1);//.then(s => this.setShoppingCartItem(s));
  }

  async removeFromCart(cartitem: ShoppingCartItem) {
    //cartitem.quantity = -1;
    this.shoppingCartService.AddRemoveCartItem(cartitem, -1);//.then(s => this.setShoppingCartItem(s));
    //shoppingcartitem.quantity -= 1;
    //this.shoppingCartService.RemoveFromCart(shoppingcartitem.product, shoppingcartitem);//.then(s => this.setShoppingCartItem(s,-1));
  }

  //setShoppingCartItem(s, quantity) {
  //  if (!s) return;
  //  if (s.quantity == 0)
  //    this.cart.shoppingCartItems.splice(this.cart.shoppingCartItems.findIndex(e => e.id === s.id), 1);
  //  if (s.quantity == 0) s.id = 0;
  //   this.cart.shoppingCartItems.filter(item => item.id === s.id).map(fItem => fItem.quantity = fItem.quantity + quantity);
  //}

  ngOnDestroy(): void {
    if (this.subscription1)
      this.subscription1.unsubscribe();

  }

}
