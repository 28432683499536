import { Component, Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { ContactService } from "../../services/contact.service";
import { Subscription } from "rxjs";
import { Meta, Title } from "@angular/platform-browser";
import { CMSService } from "../../services/cms.service";

@Component({
  selector: "app-contact",
  templateUrl: "./contact.component.html",
})
export class ContactComponent {

  cms: any = {};
  subscription2: Subscription;

  subscription1: Subscription;
  contactMessage= "";
  contactEmail= "";
  contactName = "";
  thankyou = false;
  constructor(
    private contactService: ContactService,
    @Inject(DOCUMENT) private document: Document,
    private cmsService: CMSService,
    private titleService: Title, private metaService: Meta
  ) { }
  ngOnInit() {
    this.subscription2 = this.cmsService.getbyUrl('/contact/').subscribe(
      (data) => {
        this.cms = data[0];
        this.titleService.setTitle(this.cms.title + " - Fitshake");
        this.metaService.addTags([
          { name: 'keywords', content: this.cms.metaKeyword },
          { name: 'description', content: this.cms.metaDescription },
          { name: 'robots', content: 'index, follow' }
        ]);
      },
      (err) => {
        if (err.status == 404) {
          return;
        }
      }
    );

    this.document.body.classList.add('contact');

  }
  ngOnDestroy(): void {
    this.document.body.classList.remove('contact');

    if (this.subscription1) this.subscription1.unsubscribe();
    if (this.subscription2) this.subscription2.unsubscribe();
  }

  submit() {
    
    this.subscription1 = this.contactService.contactus(this.contactName, this.contactEmail, this.contactMessage).subscribe(

      x => {
        this.thankyou = true;
       
      });
  }

  submitAnOther() {
    this.contactMessage = "";
    this.contactEmail = "";
    this.contactName = "";
        this.thankyou = false;
  }
}
