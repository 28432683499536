import { Component, OnInit } from "@angular/core";
import { ProductService } from "../../services/product.service";
import { PhotoService } from "../../services/photo.service";
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { Product } from "../../../models/product";
import { ShoppingCartItem } from "../../../models/shopping-cart";

import { ShoppingCartService } from "../../services/shopping-cart.service";
import { map, switchMap, take } from "rxjs/operators";
import { Meta, Title } from "@angular/platform-browser";


@Component({
  templateUrl: "./product-detail.html",
})
export class ProductDetailComponent implements OnInit {
  loading = true;
  btnloading = false;
  itemAdded = false;
  selectedSize = '';
  selectedFlavour = '';
  selectedPrice = '';
  quantity = 1;

  product: any = {
    name: "",
    manufacture: {},
    productReviews: [],
    productTypes: [],
    flavours: [],
    sizes:[]
  };

  manufacture: any = {}
  productId: number;
  photos: any = [];
  selectedPhoto = "";
  //shoppingCardItem = new ShoppingCartItem({
  //  id: 0,
  //  shoppingCartId: localStorage.getItem('cartId') ? Number(localStorage.getItem('cartId')) : 0,
  //  productId: 0,
  //  quantity: 0,
  //  unitPrice: 0
  //});

  constructor(
    private shoppingCartService: ShoppingCartService,
    private productService: ProductService,
    private photoService: PhotoService,
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title, private metaService: Meta
  ) {
    route.params.subscribe((p) => {
      this.productId = +p["id"];
      if (isNaN(this.productId) || this.productId <= 0) {
        router.navigate(["/products"]);
        return;
      }
    });
  }
  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });

    this.productService.getProduct(this.productId).subscribe(
      (p) => {
        this.product = p;// console.log(this.product);
        //console.log(this.product.productReviews)
        this.titleService.setTitle(this.product.name + " - Fitshake");
        this.metaService.addTags([
          { name: 'keywords', content: this.product.name },
          { name: 'description', content: this.product.metaDescription },
          { name: 'robots', content: 'index, follow' }
        ]);


        if (this.product.unitPrice != null) {
          this.selectedPrice = this.product.unitPrice;
          this.isNumberPrice = true;
        }
        else
          this.selectedPrice = this.product.priceGuide;

        this.loading = false;
      },
      (err) => {
        if (err.status == 404) {
          this.router.navigate(["/products"]);
          return;
        }
      }
    );
    this.photoService
      .getPhotos(this.productId)
      .subscribe((photos) => { this.photos = photos; this.selectedPhoto = 'photo' + photos[0].id });


    

    //if (this.shoppingCardItem.shoppingCartId)
    //  this.shoppingCartService.getCartByProduct(this.shoppingCardItem.shoppingCartId, this.productId).subscribe();//result => this.setShoppingCartItem(result[0]))
    //console.log(this.shoppingCardItem.ShoppingCartId)
    //this.shoppingCartService.getOrCreateCartId();
  }



  async addToCart() {

    this.btnloading = true;
    var shoppingCartItem = new ShoppingCartItem({
      id: 0,
      shoppingCartId: 0,
      productId: this.product.id,
      quantity: this.quantity,
      unitPrice: Number(this.selectedPrice),
      selectedFlavour: this.selectedFlavour,
      selectedSize: this.selectedSize
    });

    this.shoppingCartService.AddRemoveCartItem(shoppingCartItem, this.quantity).then(() => {
      setTimeout(() => { this.btnloading = false; this.itemAdded = true; }, 500)
      setTimeout(() => { this.itemAdded = false; }, 3000)
    });
   
    
    
  }

  onSizeChange(newValue) {
    this.getPrice();
  }

  onFlavoursChange(newValue) {
    //console.log(this.selectedFlavour);
    //   this.selectedFlavours = newValue
  }
  isNumberPrice = false;
  getPrice() {
    if (this.selectedSize) {
      var Ssize = this.product.sizes.find(f => f.name == this.selectedSize)
      this.selectedPrice = Ssize.price;
      this.isNumberPrice = true;
    }
    else {
      this.selectedPrice = this.product.priceGuide;
      this.isNumberPrice = false;
    }
  }


  addtocart_isdisabled() {
       
    
    if (this.product.flavours.length == 0 && this.isNumberPrice == true )
        return false;

    if (this.product.sizes && this.product.flavours && this.product.unitPrice != null)
      return false;


    if (this.product.sizes && this.product.flavours && this.product.unitPrice != null)
      return false;
    if (this.selectedFlavour == '' || this.selectedSize == '')
      return true;
  }



  isExpanded = true;
  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  public arr(n: number): any[] {
    var intvalue = Math.floor(n);
    if (intvalue)
      return Array(intvalue);
    return null;
  }


  //async removeFromCart() {
  //  this.shoppingCardItem.productId = this.productId;
  //  this.shoppingCartService.RemoveFromCart(this.shoppingCardItem).then(s => this.setShoppingCartItem(s));
  //}
  // async addToCart() {
  //  this.shoppingCardItem.productId = this.productId;
  //  this.shoppingCardItem.unitPrice = 100;
  //   this.shoppingCartService.AddToCart(this.shoppingCardItem).then(s => this.setShoppingCartItem(s));

  //}
  //async removeFromCart() {

  //  var shoppingCartItem = new ShoppingCartItem({
  //    id: 0,
  //    shoppingCartId: 0,
  //    productId: this.product.id,
  //    quantity: -1,
  //    unitPrice: Number(this.selectedPrice),
  //    selectedFlavour: this.selectedFlavour,
  //    selectedSize: this.selectedSize
  //  });
  //  this.shoppingCartService.RemoveFromCart(this.product, shoppingCartItem).then();//s => this.setShoppingCartItem(s));
  //}


  //async addToCartV1() {
  //  this.shoppingCartService.AddToCartV1(this.product).then(s => this.setShoppingCartItem(s));
  //}

  //async removeFromCartV1() {
  //  this.shoppingCartService.RemoveFromCart(this.product,-1).then(s => this.setShoppingCartItem(s));
  //}

  //getQuantity() {
  //  if (this.shoppingCartService.shoppingCartValue) {
  //    var cart = this.shoppingCartService.shoppingCartValue.shoppingCartItems.find(f => f.productId == this.productId);
  //    if (cart)
  //      return cart.quantity;
  //  }
  //  return 0;
  //  return this.shoppingCardItem.quantity
  //}

  //setShoppingCartItem(s) {
  //  if (!s) return;
  //  if (s.quantity == 0) s.id = 0;
  //  this.shoppingCardItem.id = s.id;
  //  this.shoppingCardItem.shoppingCartId = s.shoppingCartId;
  //  this.shoppingCardItem.productId = s.productId;
  //  this.shoppingCardItem.quantity = s.quantity;
  //  this.shoppingCardItem.unitPrice = s.unitPrice;

  //}
}


