import { Product } from "./product";

export class ShoppingCart {
  id: number;
  Created: Date;
  quantity: number;
  
  shoppingCartItems: ShoppingCartItem[] = [];


  constructor(private itemsMap) {
//    console.log("itemsMap",itemsMap);
    this.itemsMap = itemsMap || {};
    if (itemsMap) {
      this.id = itemsMap.id;
      this.Created = itemsMap.created;
   
    for (let item in itemsMap.shoppingCartItems) {
      this.shoppingCartItems.push(new ShoppingCartItem(itemsMap.shoppingCartItems[item]));
    } }
  //  console.log("this",this);
  }


  //----------------------------------------------------
  // this constructor works with
  //getCart(cartId): Observable<ShoppingCart> {
  //  return this.http.get("/api/shopping-cart/" + cartId + "/items/").pipe(map(x => new ShoppingCart(x)));
  //}
  //----------------------------------------------------
  //constructor(private itemsMap) {
  //  console.log("itemsMap:", itemsMap)
  //  this.itemsMap = itemsMap || {};
  //  if (itemsMap[0]) {
  //    this.id = itemsMap[0].shoppingCart.id;
  //    this.Created = itemsMap[0].shoppingCart.Created;
  //  }

  //  for (let item in itemsMap) {
  //    this.shoppingCartItems.push(new ShoppingCartItem(itemsMap[item]));
  //    //console.log(itemsMap[item])
  //  }
  //  console.log("this:", this);
  //}

  get totalPrice() {
    let sum = 0;
    for (let m in this.shoppingCartItems)
      sum += this.shoppingCartItems[m].totalPrice;
    return sum + this.shippingCost;
  }

  get shippingCost() {
    let sum = 0;
    for (let m in this.shoppingCartItems)
      sum += this.shoppingCartItems[m].totalPrice;

    return sum > 150 ? 0 : 9.95
  }


  get totalItemsCount() {
    let count = 0;
    for (let m in this.shoppingCartItems) {
      count += this.shoppingCartItems[m].quantity;
    }

    return count;
  }


}





export class ShoppingCartItem {
  id: number;
  shoppingCartId: number;
  productId: number;
  quantity: number;
  unitPrice: number;
  selectedFlavour: string = '';
  selectedSize: String = '';
  product: Product
  constructor(init?: Partial<ShoppingCartItem>) {
    Object.assign(this, init);
  }

  get totalPrice() {
      return this.unitPrice * this.quantity;
  }

}

export class PaypalCardItem {
  name: string;
  quantity: string;
  category = 'PHYSICAL_GOODS';
  unit_amount = new UnitAmount();
  
 
}
  class UnitAmount {
    currency_code = 'AUD';
    value: string;


}



