import { Observable, Subscriber, Subscription } from "rxjs";
//import { ShoppingCart } from '../../../shared/models/shopping-cart';
//import { ShoppingCartService } from '../../../shared/services/shopping-cart.service';
import { Component, OnInit, OnDestroy } from "@angular/core";
import { ShoppingCartService } from "../../services/shopping-cart.service";
import { ContactService } from "../../services/contact.service";
import { IPayPalConfig, ICreateOrderRequest } from "ngx-paypal";
import { ShoppingCart, PaypalCardItem } from "../../../models/shopping-cart";
import { Order } from "../../../models/order";
import { OrderService } from "../../services/order.service";
import { AuthenticationService } from "../../services/authentication.service";
import { Router } from "@angular/router";
import { first } from "rxjs/operators";


@Component({
  selector: "app-check-out",
  templateUrl: "./check-out.component.html",
  styleUrls: ["./check-out.component.css"],
})
export class CheckOutComponent implements OnInit, OnDestroy {

  public payPalConfig?: IPayPalConfig;
  order = new Order();
  loading = false;
  btnloading = false;
  subscription0: Subscription; subscription1: Subscription; subscription2: Subscription; subscription3: Subscription; contactSubscription: Subscription;
  contact = {
    Id: 0,
    email: "",
    EmailConfirmed: false,
    postcode: "",
    password: "",
    firstName: "",
    lastName: "",
    unit: "",
    address: "",
    suburb: "",
    state: "",
    guestUser: false,
    subscribe: false,
    DeliveryInstruction: "",
  };
  //cart = new ShoppingCart(0);
  cartId;
  step1 = true; step2 = false; step3 = false; alreadyExists = false;
  step4_payment = false;
  step4_DeliveryDetails = false;
  //cart$: Observable<ShoppingCart>;
  shoppingCart: any;

  constructor(
    private shoppingCartService: ShoppingCartService,
    private contactService: ContactService,
    private orderservice: OrderService,
    public auth: AuthenticationService,
    private router: Router) { }

  async ngOnInit() {
    //    console.log(this.auth.currentUserValue);

    this.shoppingCartService.CurrentShoppingCart.subscribe(r => {
    //  if (r.shoppingCartItems.length == 0)
    //    this.router.navigate(['/products']);
      this.initPaypalConfig(r);
      this.shoppingCart = r;
      
    });


    //this.cartId = await this.shoppingCartService.getOrCreateCartId();
    //this.shoppingCartService.getCart(this.cartId).subscribe(r => {
    //  this.initPaypalConfig(r)
    //  this.shoppingCart = r;
    //}
    //);  

    if (this.auth.currentUserValue) {
      this.step1 = false;
      this.subscription0 = this.contactService.getContact(this.auth.currentUserValue.id).subscribe(c => {
        this.contact.email = c.email;
        this.contact.firstName = c.firstname;
        this.contact.lastName = c.lastname;
        this.contact.unit = c.unit;
        this.contact.address = c.address;
        this.contact.suburb = c.suburb;
        this.contact.state = c.state;
        this.contact.postcode = c.postcode;
        this.contact.Id = c.id;
        this.contact.subscribe = c.subscribe;
        this.contact.guestUser = c.guestUser;
      });
    }
    //if (this.auth.currentUserValue) {
    //  this.contact.email = this.auth.currentUserValue.username;
    //  this.contact.firstName = this.auth.currentUserValue.firstName;
    //  this.contact.lastName = this.auth.currentUserValue.lastName;
    //  this.contact.unit = this.auth.currentUserValue.unit;
    //  this.contact.address = this.auth.currentUserValue.address;
    //  this.contact.suburb = this.auth.currentUserValue.suburb;
    //  this.contact.state = this.auth.currentUserValue.state;
    //  this.contact.postcode = this.auth.currentUserValue.postcode;
    //  this.contact.Id =  this.auth.currentUserValue.id;
    //}
    //this.cart$ = await this.shoppingCartService.getCart();

  }

  ngOnDestroy() {
    if (this.subscription0)
      this.subscription0.unsubscribe();
    if (this.subscription1)
      this.subscription1.unsubscribe();
    if (this.subscription2)
      this.subscription2.unsubscribe();
    if (this.contactSubscription)
      this.contactSubscription.unsubscribe();
    if (this.subscription3)
      this.subscription3.unsubscribe();

  }

  async checkEmail() {
    this.contactSubscription = this.contactService.getContacts({ email: this.contact.email, EmailConfirmed: false }).subscribe(c => {
      //console.log(c);
      if (c.length === 0) {
        //console.log("new")
        this.step1 = false;
        this.step2 = true;
      }
      else
        this.alreadyExists = true;
      this.step1 = false;
      //this.step2 = true;
      //console.log("Please log in")
    });


  }// this.contact = r.result

  createAccount() {
    
    this.contact.EmailConfirmed = false;
    //console.log(this.contact);
    this.subscription1 = this.contactService.create(this.contact).subscribe(
      c => {
        
        this.contact.Id = c.id;
        if (this.contact.password != "")
          this.login_contact();
       
      });
    this.step2 = false;
    this.step3 = true;
  }


  login_contact() {

    //    if (this.contact.guestUser == true) this.contact.password = "Continue As Guest"
    if (this.contact.password == "" || this.contact.email == "") return;
    this.auth.login(this.contact.email, this.contact.password)
      .pipe(first())
      .subscribe(c => this.contact.password = "");
  }


  async continueAsGuest() {
    this.contact.guestUser = true;
    // this.shoppingCartService.update(this.shoppingCartService.shoppingCartValue.id, this.contact.email).then();
    this.alreadyExists = false;
    this.step2 = false;
    this.step3 = true;
  }

  //saveContact() {
  //  this.subscription2 = this.contactService.create(this.contact).subscribe();
  //  this.step3 = false;
  //  this.step4_payment = true;

  //}

  saveContactDetails() {
    this.btnloading = true;
    this.subscription3 = this.contactService.create(this.contact).subscribe(
      c => {
        this.contact.Id = c.id;
        if (this.auth.currentUserValue) {
          this.auth.currentUserValue.firstName = this.contact.firstName;
          this.auth.currentUserValue.lastName = this.contact.lastName;
          this.auth.currentUserValue.unit = this.contact.unit;
          this.auth.currentUserValue.address = this.contact.address;
          this.auth.currentUserValue.suburb = this.contact.suburb;
          this.auth.currentUserValue.state = this.contact.state;
          this.auth.currentUserValue.postcode = this.contact.postcode;
          this.auth.currentUserValue.subscribe = this.contact.subscribe;
          this.auth.currentUserValue.id = this.contact.Id;
          this.auth.currentUserValue.guestUser = this.contact.guestUser;

          this.auth.updateUser(this.auth.currentUserValue)
        }
        else
          this.login_contact();



        //   console.log(this.auth.currentUserValue)

        setTimeout(() => {
          this.btnloading = false; let el = document.getElementById("payment");
          el.scrollIntoView();
        }, 1000);
      }
    );
    //this.step3 = false;
    this.step4_payment = true;

  }

  private initPaypalConfig(cart: ShoppingCart): void {

    var paypalItems = [];
    cart.shoppingCartItems.forEach(item => {
      
    

      var carditem = new PaypalCardItem();
      var totalPrice = cart.totalPrice.toFixed(2);
      carditem.name = item.product.name;
      carditem.quantity = item.quantity.toString();
      carditem.unit_amount.value = item.unitPrice.toFixed(2).toString();
      paypalItems.push(carditem);
    })
    this.payPalConfig = {
      currency: 'AUD',
      clientId: 'ASAJk5nnBYsSY1yLziaCidcDcQR-43qMt1qp-WMtkYHxMmdVIewnLKPPdB0vkoggGw4RoiNCMV3PDIOR', // add paypal clientId here
      createOrderOnClient: (data) => <ICreateOrderRequest>{
        intent: 'CAPTURE',
        purchase_units: [{
          amount: {
            currency_code: 'AUD',
            value: cart.totalPrice.toFixed(2).toString(),
            breakdown: {
              item_total: {
                currency_code: 'AUD',
                value: (cart.totalPrice - cart.shippingCost).toFixed(2).toString(),
              },
              shipping: {
                currency_code: 'AUD',
                value: cart.shippingCost.toFixed(2).toString(),
              }
            }
          },
          items: paypalItems

        }],
        application_context: {
          shipping_preference: 'NO_SHIPPING',
          brand_name: 'fitshake'
        }
        , payer: {
          address: {
            country_code: 'AU'
            , postal_code: this.contact.postcode
          },
        }

      },
      advanced: {
        commit: 'true'
      },

      style: {
        label: 'paypal',
        layout: 'vertical', //'horizontal' | 'vertical';
        size: 'responsive',
        color: 'blue',   //'gold' | 'blue' | 'silver' | 'white' | 'black';
        shape: 'pill',

      },
      onApprove: (data, actions) => {
        this.loading = true;
        //    console.log('onApprove - transaction was approved, but not authorized', data, actions);
        actions.order.get().then(details => {
          this.loading = true;
          ///     console.log('onApprove - you can get full order details inside onApprove: ', details);
        });

      },
      onClientAuthorization: (data) => {
        //console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);

        this.order.TransactionId = data.id;
        this.order.Status = data.status;
        if (this.contact.Id != 0)
          this.order.ContactId = this.contact.Id;

        this.order.ShoppingCartId = Number(this.shoppingCartService.shoppingCartValue.id);
        this.order.Amount = Number(data.purchase_units[0].amount.value);
        this.order.PayerEmail = data.payer.email_address;
        this.order.PayerName = data.payer.name.given_name + ' ' + data.payer.name.surname;
        this.order.PayerId = data.payer.payer_id;
        if (data.purchase_units[0]["payments"]["captures"][0].id != "")
          this.order.TransactionId = data.purchase_units[0]["payments"]["captures"][0].id;
        else
          this.order.TransactionId = data.id;
        this.order.Status = data.status;
        this.order.TransactionDate = new Date(data.create_time);
        this.order.PaymentMethod = "PayPal"
        //console.log(order);

        this.orderservice.create(this.order).then(r => {

          
          //if (this.contact.guestUser == true) {
          //  this.router.navigate(['/order-confirmation'], { queryParams: { cid: this.contact.Id , shid: this.order.ShoppingCartId } });
          //  this.loading = false;
          //  this.shoppingCartService.clearCart();
          //}
          //else {
            this.shoppingCartService.clearCart();
            this.router.navigate(['/account/order-invoice/' + this.order.ShoppingCartId]);
            this.loading = false;
         // }

        });
        // console.log(this.order);
        //   console.log(data)
      },
      onCancel: (data, actions) => {
        //     console.log('OnCancel', data, actions);

      },
      onError: err => {

        console.log('OnError', err);
      },
      onClick: (data, actions) => {
        //     console.log('onClick', data, actions);
      }
    };
  }

  login() {
    var snapshot = this.router.routerState.snapshot;
    this.router.navigate(['/login'], { queryParams: { returnUrl: snapshot.url } });
  }





  //async addEmail() {

  //  this.subscription = this.contactService.create(this.contact).subscribe(
  //    result => this.updateShoppingcart(result.result),
  //    error => {
  //      this.updateShoppingcart(error.error[0]),
  //        this.contact = error.error[0];
  //    }
  //  );




  //  this.step1 = false;
  //  this.step2 = true;
  //}
  //async updateShoppingcart(contact) {
  //  console.log("contact: ", contact)
  //  let cartId = await this.shoppingCartService.getOrCreateCartId();
  //  this.shoppingCartService.update(cartId, contact.email).then();
  //}







}


//var a =

//{
//  "create_time": "2020-10-22T01:32:38Z",
//  "update_time": "2020-10-22T01:34:36Z",
//  "id": "93J84988F80288057",
//  "intent": "CAPTURE",
//  "status": "COMPLETED",
//  "payer": {
//    "email_address": "sb-cqck53534195@personal.example.com",
//    "payer_id": "EEJHS9WR3M9WY",
//    "address": {
//      "country_code": "AU"
//    },
//    "name": {
//      "given_name": "John",
//      "surname": "Doe"
//    }
//  },
//  "purchase_units": [
//    {
//      "description": "The Swag Coder",
//      "reference_id": "default",
//      "amount": {
//        "value": "0.01",
//        "currency_code": "AUD",
//        "breakdown": {
//          "item_total": {
//            "value": "0.01",
//            "currency_code": "AUD"
//          },
//          "shipping": {
//            "value": "0.00",
//            "currency_code": "AUD"
//          },
//          "handling": {
//            "value": "0.00",
//            "currency_code": "AUD"
//          },
//          "insurance": {
//            "value": "0.00",
//            "currency_code": "AUD"
//          },
//          "shipping_discount": {
//            "value": "0.00",
//            "currency_code": "AUD"
//          }
//        }
//      },
//      "payee": {
//        "email_address": "sb-dk0oo3533638@business.example.com",
//        "merchant_id": "PXCN6PGE2TYEQ"
//      },
//      "items": [
//        {
//          "name": "The Swag Coder",
//          "unit_amount": {
//            "value": "0.01",
//            "currency_code": "AUD"
//          },
//          "tax": {
//            "value": "0.00",
//            "currency_code": "AUD"
//          },
//          "quantity": "1"
//        }
//      ],
//      "shipping": {
//        "name": {
//          "full_name": "John Doe"
//        },
//        "address": {
//          "address_line_1": "1 Cheeseman Ave Brighton East",
//          "admin_area_2": "Melbourne",
//          "admin_area_1": "Victoria",
//          "postal_code": "3001",
//          "country_code": "AU"
//        }
//      },
//      "payments": {
//        "captures": [
//          {
//            "status": "COMPLETED",
//            "id": "14V17259N0235283F",
//            "final_capture": true,
//            "create_time": "2020-10-22T01:34:36Z",
//            "update_time": "2020-10-22T01:34:36Z",
//            "amount": {
//              "value": "0.01",
//              "currency_code": "AUD"
//            },
//            "seller_protection": {
//              "status": "ELIGIBLE",
//              "dispute_categories": [
//                "ITEM_NOT_RECEIVED",
//                "UNAUTHORIZED_TRANSACTION"
//              ]
//            },
//            "links": [
//              {
//                "href": "https://api.sandbox.paypal.com/v2/payments/captures/14V17259N0235283F",
//                "rel": "self",
//                "method": "GET",
//                "title": "GET"
//              },
//              {
//                "href": "https://api.sandbox.paypal.com/v2/payments/captures/14V17259N0235283F/refund",
//                "rel": "refund",
//                "method": "POST",
//                "title": "POST"
//              },
//              {
//                "href": "https://api.sandbox.paypal.com/v2/checkout/orders/93J84988F80288057",
//                "rel": "up",
//                "method": "GET",
//                "title": "GET"
//              }
//            ]
//          }
//        ]
//      }
//    }
//  ],
//  "links": [
//    {
//      "href": "https://api.sandbox.paypal.com/v2/checkout/orders/93J84988F80288057",
//      "rel": "self",
//      "method": "GET",
//      "title": "GET"
//    }
//  ]
//}
