import { Component, Inject, Input, OnInit, OnDestroy } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { User } from "../../../models/user";
import { ShoppingCartService } from "../../services/shopping-cart.service";
import { ShoppingCart } from "../../../models/shopping-cart";
import { Order } from "../../../models/order";
import { AuthenticationService } from "../../services/authentication.service";
import { ContactService } from "../../services/contact.service";
import { OrderService } from "../../services/order.service";
import { Router, ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
@Component({
  selector: "app-orderinvoice",
  templateUrl: "./order-invoice.component.html",
})
export class OrderInvoiceComponent implements OnInit, OnDestroy {
 // @Input('user') user;
 // @Input('order') order;
  cartId;
  contact = {};
  order = {};
  subscription1: Subscription; subscription2: Subscription; subscription3: Subscription; 

  cart = new ShoppingCart(0);
  constructor(@Inject(DOCUMENT) private document: Document,
    public auth: AuthenticationService,
    private contactService: ContactService,
    private shoppingCartService: ShoppingCartService,
    private orderService: OrderService,
    private route: ActivatedRoute,
    private router: Router,
  )
  {
    route.params.subscribe((p) => {
      this.cartId = +p["id"];
      if (isNaN(this.cartId) || this.cartId <= 0) {
        router.navigate(["/products"]);
        return;
      }

    });
  }

  async ngOnInit() {

    //this.cartId = await this.shoppingCartService.getOrCreateCartId();
    //this.subscription2 = this.shoppingCartService.getCart(this.cartId).subscribe(r => {
    if (isNaN(this.cartId) == false && this.cartId >= 0) {
      this.subscription1 = this.contactService.getContact(this.auth.currentUserValue.id).subscribe(c => { this.contact = c; });
      this.subscription2 = this.shoppingCartService.getShoppingCart(this.cartId).subscribe(r => {
        this.cart = r;
     
      });
      this.subscription3 = this.orderService.getOrder(this.cartId).subscribe(
        x => {
          //console.log(x);
          this.order = x;
          this.shoppingCartService.clearCart();
        }
      );
    }

    
    this.document.body.classList.add("orderconfirmation");
  }
  ngOnDestroy(): void {
    this.shoppingCartService.clearCart();
    this.document.body.classList.remove("orderconfirmation");
  //  if (this.auth.currentUserValue.guestUser == true)
 //     this.auth.logout();
    if (this.subscription1)
      this.subscription1.unsubscribe();
    if (this.subscription2)
      this.subscription2.unsubscribe();
    if (this.subscription3)
      this.subscription3.unsubscribe();

  }
}
