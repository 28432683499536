import { Observable, Subscriber, Subscription } from "rxjs";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { ShoppingCartService } from "../../services/shopping-cart.service";
import { ContactService } from "../../services/contact.service";
import { ShoppingCart, PaypalCardItem } from "../../../models/shopping-cart";
import { AuthenticationService } from "../../services/authentication.service";
import { Router } from "@angular/router";
import { first } from "rxjs/operators";


@Component({
  selector: "app-sign-up",
  templateUrl: "./sign-up.component.html",
  styleUrls: ["./sign-up.component.css"],
})
export class SignUpComponent implements OnInit, OnDestroy {

    successfullyUpdated = false;   
  loading = false;
  subscription1: Subscription; subscription2: Subscription; subscription3: Subscription; contactSubscription: Subscription;
  contact = {
    Id: 0,
    email: "",
    EmailConfirmed: false,
    postcode: "",
    password: "",
    firstName: "",
    lastName: "",
    unit: "",
    address: "",
    suburb: "",
    state: "",
    DeliveryInstruction: "",
    subscribe: false,
  };
  //cart = new ShoppingCart(0);
  step1 = true; step2 = false; step3 = false; alreadyExists = false;
  step4_DeliveryDetails = false;
  //cart$: Observable<ShoppingCart>;
  shoppingCart: any;
    
  constructor(
    private shoppingCartService: ShoppingCartService,
    private contactService: ContactService,
    public auth: AuthenticationService,
    private router: Router) { }

  async ngOnInit() {
//    console.log(this.auth.currentUserValue);
    if (this.auth.currentUserValue) {
      this.router.navigate(['/account/my-details']);
    }
  //    this.contact.email = this.auth.currentUserValue.username;
  //    this.contact.firstName = this.auth.currentUserValue.firstName;
  //    this.contact.lastName = this.auth.currentUserValue.lastName;
  //    this.contact.unit = this.auth.currentUserValue.unit;
  //    this.contact.address = this.auth.currentUserValue.address;
  //    this.contact.suburb = this.auth.currentUserValue.suburb;
  //    this.contact.state = this.auth.currentUserValue.state;
  //    this.contact.postcode = this.auth.currentUserValue.postcode;
  //    this.contact.Id =  this.auth.currentUserValue.id;
  //  }
  //  //this.cart$ = await this.shoppingCartService.getCart();
    
  }

  ngOnDestroy() {
    if (this.subscription1)
      this.subscription1.unsubscribe();
    if (this.subscription2)
      this.subscription2.unsubscribe();
    if (this.contactSubscription)
      this.contactSubscription.unsubscribe();
    if (this.subscription3)
      this.subscription3.unsubscribe();
   
  }

  async checkEmail() {
    this.contactSubscription = this.contactService.getContacts({ email: this.contact.email, EmailConfirmed: false }).subscribe(c => {
      //console.log(c);
      if (c.length === 0) {
        //console.log("new")
        this.step1 = false;
        this.step2 = true;
      }
      else
        this.alreadyExists = true;
      this.step1 = false;
      //this.step2 = true;
      //console.log("Please log in")
    });


  }// this.contact = r.result

  createAccount() {
    this.contact.EmailConfirmed = false;
    //console.log(this.contact);
    this.subscription1 = this.contactService.create(this.contact).subscribe(
      x => {
            if (this.contact.password != "") {
                this.login_contact();
               
            }
        });

     

  //  this.step2 = false;
 //   this.step3 = true;
  }

  
  login_contact() {
    if (this.contact.password == "" || this.contact.email == "") return;
    this.auth.login(this.contact.email, this.contact.password)
      .pipe(first())
      .subscribe(
        c => { }
        , err => { }
        ,() => this.router.navigate(['/account/my-details'])
      );
  }


  
  //saveContact() {
  //  this.subscription2 = this.contactService.create(this.contact).subscribe();
  //  this.step3 = false;
  //  this.step4_payment = true;

  //}

  saveContactDetails() {

  
    this.subscription3 = this.contactService.create(this.contact).subscribe(
      x => {
        this.login_contact();
        //console.log(this.auth.currentUserValue)



      }
    );
     this.step3 = false;
      this.successfullyUpdated = true;
      // this.router.navigate(['/account/my-details']);
    
  }

  
  login() {
    var snapshot = this.router.routerState.snapshot;
    this.router.navigate(['/login'], { queryParams: { returnUrl: snapshot.url } });
  }





  //async addEmail() {

  //  this.subscription = this.contactService.create(this.contact).subscribe(
  //    result => this.updateShoppingcart(result.result),
  //    error => {
  //      this.updateShoppingcart(error.error[0]),
  //        this.contact = error.error[0];
  //    }
  //  );




  //  this.step1 = false;
  //  this.step2 = true;
  //}
  //async updateShoppingcart(contact) {
  //  console.log("contact: ", contact)
  //  let cartId = await this.shoppingCartService.getOrCreateCartId();
  //  this.shoppingCartService.update(cartId, contact.email).then();
  //}







}


//var a =

//{
//  "create_time": "2020-10-22T01:32:38Z",
//  "update_time": "2020-10-22T01:34:36Z",
//  "id": "93J84988F80288057",
//  "intent": "CAPTURE",
//  "status": "COMPLETED",
//  "payer": {
//    "email_address": "sb-cqck53534195@personal.example.com",
//    "payer_id": "EEJHS9WR3M9WY",
//    "address": {
//      "country_code": "AU"
//    },
//    "name": {
//      "given_name": "John",
//      "surname": "Doe"
//    }
//  },
//  "purchase_units": [
//    {
//      "description": "The Swag Coder",
//      "reference_id": "default",
//      "amount": {
//        "value": "0.01",
//        "currency_code": "AUD",
//        "breakdown": {
//          "item_total": {
//            "value": "0.01",
//            "currency_code": "AUD"
//          },
//          "shipping": {
//            "value": "0.00",
//            "currency_code": "AUD"
//          },
//          "handling": {
//            "value": "0.00",
//            "currency_code": "AUD"
//          },
//          "insurance": {
//            "value": "0.00",
//            "currency_code": "AUD"
//          },
//          "shipping_discount": {
//            "value": "0.00",
//            "currency_code": "AUD"
//          }
//        }
//      },
//      "payee": {
//        "email_address": "sb-dk0oo3533638@business.example.com",
//        "merchant_id": "PXCN6PGE2TYEQ"
//      },
//      "items": [
//        {
//          "name": "The Swag Coder",
//          "unit_amount": {
//            "value": "0.01",
//            "currency_code": "AUD"
//          },
//          "tax": {
//            "value": "0.00",
//            "currency_code": "AUD"
//          },
//          "quantity": "1"
//        }
//      ],
//      "shipping": {
//        "name": {
//          "full_name": "John Doe"
//        },
//        "address": {
//          "address_line_1": "1 Cheeseman Ave Brighton East",
//          "admin_area_2": "Melbourne",
//          "admin_area_1": "Victoria",
//          "postal_code": "3001",
//          "country_code": "AU"
//        }
//      },
//      "payments": {
//        "captures": [
//          {
//            "status": "COMPLETED",
//            "id": "14V17259N0235283F",
//            "final_capture": true,
//            "create_time": "2020-10-22T01:34:36Z",
//            "update_time": "2020-10-22T01:34:36Z",
//            "amount": {
//              "value": "0.01",
//              "currency_code": "AUD"
//            },
//            "seller_protection": {
//              "status": "ELIGIBLE",
//              "dispute_categories": [
//                "ITEM_NOT_RECEIVED",
//                "UNAUTHORIZED_TRANSACTION"
//              ]
//            },
//            "links": [
//              {
//                "href": "https://api.sandbox.paypal.com/v2/payments/captures/14V17259N0235283F",
//                "rel": "self",
//                "method": "GET",
//                "title": "GET"
//              },
//              {
//                "href": "https://api.sandbox.paypal.com/v2/payments/captures/14V17259N0235283F/refund",
//                "rel": "refund",
//                "method": "POST",
//                "title": "POST"
//              },
//              {
//                "href": "https://api.sandbox.paypal.com/v2/checkout/orders/93J84988F80288057",
//                "rel": "up",
//                "method": "GET",
//                "title": "GET"
//              }
//            ]
//          }
//        ]
//      }
//    }
//  ],
//  "links": [
//    {
//      "href": "https://api.sandbox.paypal.com/v2/checkout/orders/93J84988F80288057",
//      "rel": "self",
//      "method": "GET",
//      "title": "GET"
//    }
//  ]
//}
