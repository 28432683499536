import { Component, Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { Meta, Title } from "@angular/platform-browser";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
})
export class HomeComponent {

  constructor(
    @Inject(DOCUMENT) private document: Document

    , private titleService: Title, private metaService: Meta
  ) { }
  ngOnInit() {

    this.titleService.setTitle("Fitshake");

    this.metaService.addTags([
      { name: 'keywords', content: "supplement, protein powder" },
      { name: 'description', content: "We are a team of sports, health and lifestyle enthusiasts who believe a balanced, nutritious and sustainable diet could open up doors to a better life and more opportunities for improvement." },
      { name: 'robots', content: 'index, follow' }
    ]);

    this.document.body.classList.add('home');

  }
  ngOnDestroy(): void {
    this.document.body.classList.remove('home');
  }
}
