import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
//import { AuthService } from "./services/auth.service";
import { environment } from "./../environments/environment";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { RouterModule } from "@angular/router";
//import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AppComponent } from "./app.component";
import { NavMenuComponent } from "./components/nav-menu/nav-menu.component";
import { BlogComponent } from "./components/blog/blog.component";
import { HomeComponent } from "./components/home/home.component";
import { ContactComponent } from "./components/contact/contact.component";
import { AboutComponent } from "./components/about/about.component";
import { PrivacyComponent } from "./components/privacy/privacy.component";
import { TermsComponent } from "./components/terms/terms.component";
import { shippingReturnComponent } from "./components/shippingReturn/shippingReturn.component";
import { ProductListComponent } from "./components/product-list/product-list";
import { ProductService } from "./services/product.service";
import { FooterComponent } from "./components/footer/footer.component";
import { PhotoService } from "./services/photo.service";
import { ProductDetailComponent } from "./components/product-detail/product-detail";
import { ShopingCartComponent } from "./components/shoping-cart/shoping-cart.component";
import { SignUpComponent } from "./components/sign-up/sign-up.component";

import { LoginComponent } from "./components/login/login.component";
import { ForgotPasswordComponent } from "./components/forgot-password/forgot-password.component";
import { AdminProductsComponent } from "./admin/components/admin-products/admin-products.component";
import { AdminOrdersComponent } from "./admin/components/admin-orders/admin-orders.component";
//import { AuthGuard } from "./services/auth-guard.service";

import { CheckOutComponent } from "./components/check-out/check-out.component";
import { UserService } from "./services/user.service";
//import { AdminAuthGuard } from "./services/admin-auth-guard.service";
import { AdminProductFormComponent } from "./admin/components/admin-product-form/admin-product-form.component";
import { ShoppingCartService } from "./services/shopping-cart.service";
import { ContactService } from "./services/contact.service";
import { NgxPayPalModule } from 'ngx-paypal';
import { OrderService } from "./services/order.service";




import { BasicAuthInterceptor } from "./helper/basic-auth.interceptor";
import { ErrorInterceptor } from "./helper/error.interceptor";

import { AuthGuard } from './helper/auth.guard';
import { OrderConfirmationComponent } from "./components/order-confirmation/orderconfirmation.component";
import { NumberDirective } from "./directives/number-only.directive";
import { ProductReviewComponent } from "./components/product-review/product-review.component";
import { RatingComponent } from "./components/rating/rating.component";

import { ProductQAComponent } from "./components/product-qa/product-qa.component";
import { ResetPasswordComponent } from "./components/reset-password/reset-password.component";
import { MyOrdersComponent } from "./account/components/my-orders/my-orders.component";
import { OrderInvoiceComponent } from "./components/order-invoice/order-invoice.component";
import { MyDetailsComponent } from "./account/components/my-details/my-details.component";
import { CMSService } from "./services/cms.service";




@NgModule({
    declarations: [
        AppComponent,
        NavMenuComponent,
        FooterComponent,
        HomeComponent,
        ProductListComponent,
        ProductDetailComponent,
        LoginComponent,
        ForgotPasswordComponent,
        ProductListComponent,
        ShopingCartComponent,
        SignUpComponent,
        ContactComponent,
        AboutComponent,
        BlogComponent,
        PrivacyComponent,
        TermsComponent,
        shippingReturnComponent,
        AdminProductsComponent,
        AdminOrdersComponent,
        CheckOutComponent,
        AdminProductFormComponent,
        OrderConfirmationComponent,
        OrderInvoiceComponent,
        NumberDirective,
        ProductReviewComponent,
        ProductQAComponent,
        RatingComponent,
        ResetPasswordComponent,
        MyOrdersComponent,
        MyDetailsComponent,
    ],
    imports: [

        BrowserModule.withServerTransition({ appId: "ng-cli-universal" }),
        HttpClientModule,
        ReactiveFormsModule,
        NgxPayPalModule,
        //NgbModule,
        FormsModule,
        RouterModule.forRoot([
            { path: "", component: HomeComponent, pathMatch: "full" },
            { path: "products", component: ProductListComponent },
            { path: "products/best-sellers", component: ProductListComponent },
            { path: "contact", component: ContactComponent },
            { path: "about", component: AboutComponent },
            { path: "blog", component: BlogComponent },
            { path: "privacy", component: PrivacyComponent },
            { path: "shippingReturn", component: shippingReturnComponent },
            { path: "terms", component: TermsComponent },
            { path: "products/:id", component: ProductDetailComponent },
            { path: "shopping-cart", component: ShopingCartComponent },
            { path: "login", component: LoginComponent },
            { path: "forgot-password", component: ForgotPasswordComponent },
            { path: "reset-password", component: ResetPasswordComponent },
            { path: "order-confirmation", component: OrderConfirmationComponent },
            { path: "sign-up", component: SignUpComponent },
            {
                path: "account/my-details",
                component: MyDetailsComponent,
                canActivate: [AuthGuard],
            },

            {
                path: "account/order-invoice/:id",
                component: OrderInvoiceComponent,
                canActivate: [AuthGuard],
            },
            {
                path: "account/my-orders",
                component: MyOrdersComponent,
                canActivate: [AuthGuard],
            },

            {
                path: "check-out",
                component: CheckOutComponent,
            },
        ]),
    ],
    providers: [
        ProductService,
        PhotoService,
        AuthGuard,
        UserService,
        ShoppingCartService,
        ContactService,
        OrderService,
        CMSService,
        { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    ],


    bootstrap: [AppComponent],
})
export class AppModule { }
