import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable } from "rxjs";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AuthenticationService } from "../../services/authentication.service";
import { first } from "rxjs/operators";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService
    , private titleService: Title
  ) {
    // redirect to home if already logged in
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {
    this.titleService.setTitle("Login - Fitshake");
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    localStorage.setItem("returnUrl", this.returnUrl);
    //console.log(this.returnUrl);
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  onSubmit() {

    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.authenticationService.login(this.f.username.value, this.f.password.value)
      .pipe(first())
      .subscribe(
        data => {
       //   localStorage.setItem('cUser', JSON.stringify(data));
        //  console.log(data)
          this.router.navigate([this.returnUrl]);
        },
        error => {
          this.error = 'Username or password is incorrect';
          // error;
          this.loading = false;
        });
    
  }


  //user$: Observable<firebase.User>;
  //private errorMessage = "";
  //private successMessage = "";
  //  constructor(private auth: AuthService, private route: ActivatedRoute) {}

  //email: string;
  //password: string;

  //signup() {
  //  this.auth.signup(this.email, this.password);
  //  this.email = this.password = "";
  //}

  //login() {
  //  this.auth.login();
  //  let returnUrl = this.route.snapshot.queryParamMap.get("returnUrl") || "/";
  //  console.log(returnUrl);
  //  localStorage.setItem("returnUrl", returnUrl);
  //}
//     <div class="row mt-3" >
//  <div class="col-sm-6 text-left" >
//    <button (click)="signup()"
//    [disabled] = "!email || !password"
//class="btn btn-primary" >
//  Signup
//  < /button>
//  < /div>
//  < div class="col-sm-6 text-right" >
//    <button[disabled]="loading" class="btn btn-primary" >
//      <span * ngIf="loading" class="spinner-border spinner-border-sm mr-1" > </span>
//Login
//  < /button>
//  < div * ngIf="error" class="alert alert-danger mt-3 mb-0" > {{ error }}</div>



//    < !--< button(click)="login()"
//    [disabled] = "!email || !password"
//class="btn btn-primary" >
//  Login
//  < /button>-->
//  < /div>
//  < p class="mt-4 mb-4 align-center d-block" > ----OR ----< /p>
//    < /div>


}
