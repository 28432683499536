
import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
@Injectable()
export class PhotoService {
  constructor(private http: HttpClient) { }
  upload(productId, altText, photo) {
    var formData = new FormData();
    formData.append('file', photo);
    formData.append('altText', altText);
    return this.http.post(`/api/products/${productId}/photos`, formData);
  }
  getPhotos(productId) {
    return this.http.get(`/api/products/${productId}/photos`);
  }
}
