import { Component, OnInit, Input, OnChanges } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AuthenticationService } from "../../services/authentication.service";

import { ProductReview } from "../../../models/product";

import { ProductService } from "../../services/product.service";


@Component({
  selector: "app-product-qa",
  templateUrl: "./product-qa.component.html",
})
export class ProductQAComponent implements OnInit, OnChanges {
  @Input() productReviews: ProductReview[];
  @Input() productID: number;
  pReviews: ProductReview[];
  error = '';
  showThankYou = false;
  ratingClicked: number;
  itemIdRatingClicked: string;
  newReview: ProductReview
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private productService: ProductService,
    private authenticationService: AuthenticationService
  ) { }

  ngOnChanges() {
    if (this.productReviews)
      this.productReviews = this.productReviews.filter(f => f.qa == true)
  }
  ngOnInit() {
    
    if (this.productReviews)
      this.productReviews= this.productReviews.filter(f => f.qa == true)
  
    this.newReview = {
      Id: 0,
      ProductId: this.productID,
      Approved :false,
      qa : true,
      ContactID: this.authenticationService.currentUserValue != null ? this.authenticationService.currentUserValue.id : null,
      ContactName: this.authenticationService.currentUserValue != null ? this.authenticationService.currentUserValue.firstName :"",
      Email: this.authenticationService.currentUserValue != null ? this.authenticationService.currentUserValue.username : "",
      Title: "Question",
      Comment: "",
      Reply: "",
      rating: this.ratingClicked,
      Created: null,
      LastUpdate: null
    };

 //   console.log("PID:", this.productID);
 //   console.log("productReview:", this.productReviews);

  }

  submitReview() {
    this.productService.saveProductReview(this.newReview).subscribe();
    this.showThankYou = true;
    //console.log(this.newReview);

    

      }



}

