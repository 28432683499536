import { Component, OnInit, OnDestroy } from '@angular/core';
import { ShoppingCartService } from '../../../services/shopping-cart.service';
import {  Product } from '../../../../models/product';
import { ShoppingCart, ShoppingCartItem } from '../../../../models/shopping-cart';
import { map, switchMap, take } from "rxjs/operators";
import { Observable, Subscription } from "rxjs";
import { Router } from '@angular/router';
import { OrderService } from 'src/app/services/order.service';
import { AuthenticationService } from 'src/app/services/authentication.service';



@Component({
  selector: 'app-shoping-cart',
  templateUrl: './my-orders.component.html',
  styleUrls: ['./my-orders.component.css']
})
export class MyOrdersComponent implements OnInit, OnDestroy {
  loading = true;

  cart= new ShoppingCart(0);
  subscription1: Subscription;  
  constructor(private orderService: OrderService, 
    public auth: AuthenticationService,
    private router: Router) { }
  orders;
  async ngOnInit() {
    this.subscription1 = this.orderService.getContactOrders(this.auth.currentUserValue.id).subscribe(x => {
      //console.log(x);
      this.orders = x;
      this.loading = false;
    });
  }

  ngOnDestroy(): void {
    if (this.subscription1)
      this.subscription1.unsubscribe();

  }

}
