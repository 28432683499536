import { Component, Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { AuthenticationService } from "src/app/services/authentication.service";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
})
export class ResetPasswordComponent {
  error = "";
  showThankYou = false;
  contact = {
    email: "",
    password: "",
    activationKey :"",
  };

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document
  ) { }
  ngOnInit() {

    this.contact.email = this.route.snapshot.queryParamMap.get('e');
    this.contact.activationKey = this.route.snapshot.queryParamMap.get('c');
    //this.document.body.classList.add('resetPassword');

  }
  resetPassword(){


    this.authenticationService.resetPassword(this.contact.email,this.contact.password,this.contact.activationKey) .subscribe(
      data => {
        this.showThankYou = true;
        //console.log(data);
      },
      error => {
        this.error = error;
      });

  }

  ngOnDestroy(): void {
   // this.document.body.classList.remove('resetPassword');
  }

}
