import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { CMSService } from "../../services/cms.service";
import { Subscription } from "rxjs";
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: "app-about",
  templateUrl: "./about.component.html",
})
export class AboutComponent implements OnInit, OnDestroy {
  loading = true;
  cms: any = {};
  subscription1: Subscription;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private cmsService: CMSService,
    private titleService: Title, private metaService: Meta
  ) { }
  ngOnInit() {

    this.subscription1 = this.cmsService.getbyUrl('/about/').subscribe(
      (data) => {
        this.cms = data[0];

        this.titleService.setTitle(this.cms.title + " - Fitshake");
        this.metaService.addTags([
          { name: 'keywords', content: this.cms.metaKeyword },
          { name: 'description', content: this.cms.metaDescription },
          { name: 'robots', content: 'index, follow' }
        ]);
        this.loading = false;

        
      },
      (err) => {
        if (err.status == 404) {
          return;
        }
      });

    



 


    this.document.body.classList.add('about');

  }
  ngOnDestroy(): void {
    if (this.subscription1) this.subscription1.unsubscribe();
    this.document.body.classList.remove('about');

  }
}
