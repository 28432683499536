//import { AppUser } from '../models/app-user';
import { AngularFireDatabase, AngularFireObject } from "angularfire2/database";
//import {FirebaseObjectObservable} from 'angularfire2/'
import { Injectable } from "@angular/core";
import * as firebase from "firebase/app";
import { from, Observable } from "rxjs";
import { AppUser } from "./../../models/app-user";
import { map, switchMap } from "rxjs/operators";

@Injectable()
export class UserService {
  constructor(private db: AngularFireDatabase) {}

  save(user: firebase.User) {
    this.db.object("/users/" + user.uid).update({
      name: user.displayName,
      email: user.email,
    });
  }

  get(uid: string): AngularFireObject<AppUser> {
    return this.db.object("/users/" + uid);
  }
}
