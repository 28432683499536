import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { ProductService } from "src/app/services/product.service";
import { forkJoin } from "rxjs";
import { SaveProduct } from "src/models/product";

@Component({
  selector: "app-product-form",
  templateUrl: "./admin-product-form.component.html",
  styleUrls: ["./admin-product-form.component.css"],
})
export class AdminProductFormComponent implements OnInit {
  
  product: SaveProduct = {
    id: 0,
    Name: "",
    ManufactureId: 0,
    ShortDescription: "",
    FullDescription: "",
    NutritionInfo: "",
    specifications: "",
    AdminComment: "",
    MetaDescription: "",
    Featured: false,
    Published: false,
    SuggestedUse: "",
    PriceGuide:"",
    categories: [],
  };
  id;
  categories: any = [];
  dietCategories: any = [];
  manufacturers: any = []; 

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private productService: ProductService
  ) {
    //this.categories$ = productService.getCategories("");

    this.id = this.route.snapshot.paramMap.get("id");

    //if (this.id) this.product = this.productService.getProduct(this.id);
  }

  private setProduct(v) {
    this.product.id = v.id;
    this.product.ManufactureId = v.Manufacture.id;
    //this.product.modelId = v.model.id;
    //this.product.isRegistered = v.isRegistered;
    //this.product.contact = v.contact;
    v.categories.forEach((element) => {
      this.product.categories.push(parseInt(element.id));
    });
  }
  //   this.veh

  save(product) {
    console.log(product);
    // if (this.id) this.productService.update(product);
    // else this.productService.create(product);

    // this.router.navigate(["/admin/products"]);
  }

  delete() {
    if (!confirm("Are you sure you want to delete this product?")) return;

    this.productService.delete(this.id);
    this.router.navigate(["/admin/products"]);
  }
  onCategoriesToggle(featureId, $event) {
    if ($event.target.checked) this.product.categories.push(featureId);
    else {
      var index = this.product.categories.indexOf(featureId);
      this.product.categories.splice(index, 1);
    }
  }

  ngOnInit() {
    var sources = [this.productService.getCategories("results"),
      this.productService.getCategories("diet"),
      this.productService.getManufacturers()];
    if (this.id) sources.push(this.productService.getProducts(this.id));
    forkJoin(sources).subscribe(
      (data) => {
        this.categories = data[0];
        this.dietCategories = data[1];

        this.manufacturers = data[2];
        
        if (this.id) {
          this.setProduct(data[3]);
          //  this.populateModels();
        }
      },
      (err) => {
        if (err.status == 404) this.router.navigate["/home"];
      }
    );
  }
}
