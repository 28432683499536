
import { Component, OnInit, Input } from "@angular/core";
import { ProductService } from "../../services/product.service";
import { PhotoService } from "../../services/photo.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Meta, Title } from "@angular/platform-browser";

@Component({
  templateUrl: "./product-list.html",
})
export class ProductListComponent implements OnInit {
  
  private readonly PAGE_SIZE = 100;
  //filter: any = {};
  loading = false;
  pageTitle = "All products";
  queryResult: any = {};
  query: any = {
    pageSize: this.PAGE_SIZE,
    categoryId: [],
    manufactureIds: [],
    keyWord: "",
    Published: true,

  };
  cateroriesResults: any = [];
  cateroriesDiet: any = [];
  manufactures: any = [];
  constructor(private productService: ProductService,
    private photoService: PhotoService,
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title, private metaService: Meta
  ) {
    if (route.snapshot.routeConfig.path == "products/best-sellers") {
      this.query.bestSeller = true;
      this.pageTitle = "Best Sellers"
     }

    route.queryParams.subscribe((p) => {
      
      this.query.sortBy = "Rating"
      this.query.isSortAscending = false;
      
   //   if (p["best-sellers"])
   //     this.query.bestSeller = true;
      

      if (p["keyword"])
        this.query.keyWord = p["keyword"];
      else
        this.query.keyWord = "";

      this.populateProducts();
    //  console.log("this.query.keyWord", this.query.keyWord )
    });

  }
  ngOnInit() {
    this.titleService.setTitle("Product List - Fitshake");
    this.metaService.addTags([
      { name: 'keywords', content: "fitshake, " + this.pageTitle },
      { name: 'description', content: "fitshake " + this.pageTitle },
      { name: 'robots', content: 'index, follow' }
    ]);


    //this.populateProducts();
   
    this.productService.getCategories("results").subscribe((result) => (this.cateroriesResults = result));
    this.productService.getCategories("diet").subscribe((result) => (this.cateroriesDiet = result));
    this.productService.getManufacturers().subscribe((result) => (this.manufactures = result));

    
       
  }

  private populateProducts() {
    this.loading = true;
    //console.log(this.query);
    this.productService

      .getProducts(this.query)
      .subscribe((result) => {
        this.queryResult = result;
        this.loading = false;
        console.log("result", this.queryResult)

      });

 
  }
  onManufacturersToggle(manufactureId, $event) {
    if ($event.target.checked) this.query.manufactureIds.push(manufactureId);
    else {
      var index = this.query.categoryId.indexOf(manufactureId);
      this.query.manufactureIds.splice(index, 1);
    }
    console.log(this.query.manufactureIds)
    this.populateProducts();
    //console.log(this.query.caterories);

  }
  onCateroriesToggle(categoryId, $event) {
    if ($event.target.checked) this.query.categoryId.push(categoryId);
    else {
      var index = this.query.categoryId.indexOf(categoryId);
      this.query.categoryId.splice(index, 1);
    }
    this.populateProducts();
    //console.log(this.query.caterories);

  }

  public arr(n: number): any[] {
    var intvalue = Math.floor(n);
    if (intvalue)
      return Array(intvalue);
    return null;
  }

  sortBy(columnName) {
    //if (this.query.sortBy === columnName) {
    //  this.query.isSortAscending = !this.query.isSortAscending;
    //} else {
    


    this.query.sortBy = columnName;
    if (columnName == "Rating")
      this.query.isSortAscending = false;
    else
      this.query.isSortAscending = true;
    //}
    this.populateProducts();
  }

  

  isExpanded = true;

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }
}
