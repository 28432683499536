

export class Order {
  Id: number;
  ContactId: number;
  ShoppingCartId: number;
  Amount: number;
  PayerEmail: string;
  PayerName: string;
  PayerId: string;
  TransactionId: string;
  Status: string
  TransactionDate: Date;
  PaymentMethod: string;
}
