// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCbEidVTyGa74fng3ssG0ZwcGb2wdLhhBY",
    authDomain: "fitshake-31230.firebaseapp.com",
    databaseURL: "https://fitshake-31230.firebaseio.com",
    projectId: "fitshake-31230",
    storageBucket: "fitshake-31230.appspot.com",
    messagingSenderId: "248847680249",
    appId: "1:248847680249:web:4bf636a6004620e7b4d828",
    measurementId: "G-H66F4P3CE2",
  },
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
