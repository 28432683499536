import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';




@Injectable()
export class OrderService {

  constructor(private http: HttpClient) { }



  public create(order) {
    return this.http.post<any>("/api/orders", order).toPromise();
    //return this.http.post<any>("/api/order", order);//.pipe(map(res => this.cartId = res.result.id))
  };


  public getOrder(shoppingCartID) {
    return this.http.get<any>("/api/orders/" +  shoppingCartID);
    //return this.http.post<any>("/api/order", order);//.pipe(map(res => this.cartId = res.result.id))
  };

  public getContactOrders(contactID) {
    return this.http.get<any>("/api/orders/contact/" + contactID);
    //return this.http.post<any>("/api/order", order);//.pipe(map(res => this.cartId = res.result.id))
  };
}



