import { Component, Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { CMSService } from "../../services/cms.service";
import { Subscription } from "rxjs";
import { Meta, Title } from "@angular/platform-browser";

@Component({
  selector: "app-blog",
  templateUrl: "./blog.component.html",
})
export class BlogComponent {
  cms: any = {};
  subscription1: Subscription;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private cmsService: CMSService,
    private titleService: Title, private metaService: Meta
  ) { }
  ngOnInit() {
    this.subscription1 = this.cmsService.getbyUrl('/blog/').subscribe(
      (data) => {
        this.cms = data[0];
        this.titleService.setTitle(this.cms.title + " - Fitshake");
        this.metaService.addTags([
          { name: 'keywords', content: this.cms.metaKeyword },
          { name: 'description', content: this.cms.metaDescription },
          { name: 'robots', content: 'index, follow' }
        ]);
      },
      (err) => {
        if (err.status == 404) {
          return;
        }
      }
    );
    this.document.body.classList.add('blog');

  }
  ngOnDestroy(): void {
    if (this.subscription1)
      this.subscription1.unsubscribe();
    this.document.body.classList.remove('blog');
  }
}
