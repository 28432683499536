import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';




@Injectable()
export class CMSService {

  constructor(private http: HttpClient) { }


  public get(Id) {
    return this.http.get<any>("/api/cms/" +  Id);
  };

  public getbyUrl(url) {
    return this.http.get<any>("/api/cms/?url=" + url );
  };
}



