import { Component, Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { AuthenticationService } from "src/app/services/authentication.service";


@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
})
export class ForgotPasswordComponent {
  showThankYou = false;
  error="";
    contact = {
    email: "",
  };
  constructor(
    private authenticationService: AuthenticationService,
@Inject(DOCUMENT) private document: Document
  ) { }
  ngOnInit() {
    this.document.body.classList.add('ForgotPassword');

  }
  ngOnDestroy(): void {
    this.document.body.classList.remove('ForgotPassword');
  }

  requestResetPassword(){
    this.authenticationService.requestResetPassword(this.contact.email) .subscribe(
      data => {
        this.showThankYou = true;
      },
      error => {
        this.error = "This email address hasn't been registered";
      });
  }
}
